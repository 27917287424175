import React, { useCallback, useEffect, useState } from 'react';
import './LoginPage.scss';
import { loadFromApi } from "../../../api/baseApi";
import { useToasts } from 'react-toast-notifications';
import { FORGOT_PASSWORD_URL, LOGIN_URL}  from '../../../api/loginApi';
import loginPage from '../../../assets/images/loginpage.png';
import {Helmet} from "react-helmet";
import { ResetPassword } from '../resetPasswordPage/ResetPassword';
import LoadingDisplay from '../../common/LoadingDisplay';

export const LoginPage = (props: any) => {

    const { addToast } = useToasts();
   const setApiError = useCallback(
        (error?: string) => error && addToast(error, { appearance: 'error', autoDismiss: true }),
        [addToast]
    );

    const [userName, setUsername] = useState('');
    const [passwordExpired, setPasswordExpired ] = useState(false);
    const [password, setPassword] = useState('');
    const [ token, setToken] = useState('')
    const [ error, setError] = useState(false);
    const [ loading, setLoading] = useState(false)
    const validateFields = () => {
        setLoading(true)
        const LoginPageApiWrapper = loadFromApi(setApiError);
        const loadUpdateAccountData = async () => {
            var formdata = new FormData();
            formdata.append("Username", `${userName}`); 
            formdata.append("Password", `${password}` );
            var requestOptions: any = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };
            const LOGIN_URL = process.env.REACT_APP_LOGIN_PAGE_URL + "/validateuser";
            LOGIN_URL && fetch(LOGIN_URL, requestOptions)
            .then(response => { 
            if(response.status === 200){
                return response.json();
            }
            else {
                return 'error';
            }
            })
            .then((result : any) => {
                setLoading(false)
                if(result !=='error'){
                    if(result?.passwordExpired){
                        sessionStorage.setItem('userName', userName );
                        props.setPasswordExpired(true);
                        window.location.href = '/resetPassword';
                    }
                    if(result.passwordExpires <=10){
                        addToast(`Password expires in ${result.PasswordExpires} days`, { appearance: 'info', autoDismiss: false })
                    }
                    setToken(result.token); 
                    props.setAuthToken && props.setAuthToken(result.token)
                }

                else {
                    setError(true)
                }
               
            })
            .catch(error => {
                setLoading(false)
                console.log('error', error)});
        };   
        loadUpdateAccountData();
    }

    const [type, setType] = useState('password')

    return <> {!passwordExpired ?
            <><Helmet
                bodyAttributes={{
                    class: 'login-page'
                }}>
            </Helmet>
        <div className='login card'>
        {loading && <LoadingDisplay />}
                <div className='login-details'>
                    <img className='logo' src='https://reliantpayment.com/wp-content/themes/reliant/assets/img/main-logo.svg'></img>
                    <p>Welcome to Reliant Creditor Portal </p>
                    <div className='row'>
                        <input type='text' placeholder='Username' onChange={(e) => setUsername(e.target.value)}></input>
                    </div>
                    <div className='row'>
                        <input type={type} placeholder='Password' onChange={(e) => setPassword(e.target.value)}></input>
                         <span className={`e-icons e-eye${type=='text'? '' :'-slash'}`} onClick={() => {
                            if(type==="text") setType("password")
                            else setType("text")
                        }}></span>
                    </div>
                    {FORGOT_PASSWORD_URL && <a className='login-forgot'  href={FORGOT_PASSWORD_URL}>Forgot Password</a>}
                    <div className='login-button'>
                        <button onClick={() => validateFields()}>login</button> 
                    </div>
                    
                    {(props.loginError || error) && <p className='login-error'>Invalid Credentials</p>}
                </div>
                <div className='login-block'>
                    <img src={loginPage}></img>
                </div>
                
        </div></> :<>
                    <ResetPassword/>
        </>
    }
    </>
}