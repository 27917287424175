import {
    ExcelExportProperties,
    ExcelQueryCellInfoEventArgs,
    GridComponent,
    PdfExportProperties,
    PdfQueryCellInfoEventArgs,
    ValueAccessor
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-react-navigations';
import { useRef } from 'react';
import { serverDateToDisplayDate } from './dateUtils';
import { getPropertyByString } from './objectUtils';

export type InternalValueAccessor<RowType> = (field: string, data: RowType) => Object;

export const vaWrapper: <RowType>(
    internalValueAccessor: InternalValueAccessor<RowType>
) => ValueAccessor =
    <RowType>(internalValueAccessor: InternalValueAccessor<RowType>) =>
    (field, data, _columnModel) =>
        internalValueAccessor(field, data as RowType);

export const useCommonGridOptions = (exportFileName: string) => {
    const ref = useRef<GridComponent>(null);

    const toolbarClick = (args?: ClickEventArgs) => {
        if (!ref.current || !args) {
            return;
        }
        switch (args.item.text) {
            case 'PDF Export': {
                const exportProperties: PdfExportProperties = {
                    fileName: exportFileName + '.pdf',
                    pageOrientation: 'Landscape',
                    theme: {
                        caption: {
                            bold: true,
                            fontSize: 12,
                            fontColor: '#000000'
                        },
                        header: {
                            bold: true,
                            fontSize: 12,
                            fontColor: '#000000'
                        },
                        record: {
                            fontSize: 10,
                            fontColor: '#000000'
                        }
                    },
                    allowHorizontalOverflow: false
                };
                ref.current.pdfExport(exportProperties);
                break;
            }
            case 'Excel Export': {
                const exportProperties: ExcelExportProperties = {
                    fileName: exportFileName + '.xlsx'
                };
                ref.current.excelExport(exportProperties);
                break;
            }
            case 'CSV Export': {
                const exportProperties: ExcelExportProperties = {
                    fileName: exportFileName + '.csv'
                };
                ref.current.csvExport(exportProperties);
                break;
            }
        }
    };

    const prepGridForExport = (args?: PdfQueryCellInfoEventArgs | ExcelQueryCellInfoEventArgs) => {
        if (!args || !args.column) {
            return;
        }

        if (args.value === '' && args.data) {
            // @ts-ignore
            args.value = args.column.valueAccessor(args.column.field, args.data) + '';
        }

        if (args.value === 'undefined') {
            args.value = '';
        }
    };

    const pdfQueryCellInfo = (args?: PdfQueryCellInfoEventArgs) => {
        prepGridForExport(args);
    };

    const excelQueryCellInfo = (args?: ExcelQueryCellInfoEventArgs) => {
        prepGridForExport(args);
    };

    return {
        ref,
        allowExcelExport: true,
        allowPdfExport: true,
        toolbar: ['ExcelExport', 'PdfExport', 'CsvExport'],
        toolbarClick,
        allowGrouping: false,
        allowSorting: false,
        gridLines: 'Horizontal',
        className: 'e-grid ram-grid',
        allowTextWrap: false,
        textWrapSettings: { wrapMode: 'Both' },
        pdfQueryCellInfo,
        excelQueryCellInfo,
        wrapMode: 'Header',
        rowHeight: 20
    };
};

//Common Value Accessor functions

export const formatDate = <T>(field: string, data?: T) => {
    if (!data) {
        return '';
    }

    let dateValue = getPropertyByString(data, field);
    if (!dateValue) {
        return '';
    }

    return serverDateToDisplayDate(dateValue);
};


