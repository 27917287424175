import { httpRequest } from './baseApi';

const BASE_URL = process.env.REACT_APP_CREDITOR_API_URL + '/ScheduledPayments/';
const FUTURE_PAYMENTS_BY_URL = BASE_URL + 'ScheduledPaymentsByDate';
const CREDITORNAME_BY_ID_URL = BASE_URL + 'creditorNameById'; 
const REJECT_URL =  BASE_URL + 'RejectScheduledPayment';
const UPDATE_ACCOUNT_URL = BASE_URL + 'updateAcctNumb';
const UPDATE_AMOUNT_URL = BASE_URL + 'UpdateDepositAmt';
const SCHEDULE_PAYMENTS_ENABLED_URL = BASE_URL + 'SchedulePaymentsEnabled';

export const getScheduledPayments = (id: any, begin: any, end : any) => httpRequest<any>(`${FUTURE_PAYMENTS_BY_URL}?creditorId=${id}&beginDate=${begin}&endDate=${end}`, 'get');
export const getCreditorNameById = (id: any) => httpRequest<any>(`${CREDITORNAME_BY_ID_URL}?creditorId=${id}`, 'get');
export const getRejectData = (data: any) => httpRequest<any>(`${REJECT_URL}`, 'post', data);
export const updateAccountData = (data: any) => httpRequest<any>(`${UPDATE_ACCOUNT_URL}?settlementID=${data.id}&newAcctNumb=${data.account}`,'post', data);
export const updateAmountData = (data: any) => httpRequest<any>(`${UPDATE_AMOUNT_URL}`,'post', data);
export const schedulePaymentsEnabled = () => httpRequest<any>(`${SCHEDULE_PAYMENTS_ENABLED_URL}`, 'get');