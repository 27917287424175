import { env } from 'process';
import { httpRequest, Urls } from './baseApi';

const BASE_URL = process.env.REACT_APP_CREDITOR_API_URL;

const CHECKLIST_URL = BASE_URL + '/CreditorPymtChecklist/ACHCreditorChecklist';
const REMINDER_URL = BASE_URL + '/CreditorPymtChecklist/SendIntentFileReminder';
const UNDO_VERIFICATION_URL = BASE_URL + '/CreditorPymtChecklist/UndoVerification';

export const updateChecklist = (data: any) => httpRequest<any>(`${CHECKLIST_URL}`, 'put', data);
export const getChecklist = () => httpRequest<any>(`${CHECKLIST_URL}`, 'get');
export const sendReminder = (id: any) => httpRequest<any>(`${REMINDER_URL}?creditorID=${id}`, 'post');
export const undoVerification = (checkListId: any, creditorId: any) => httpRequest<any>(`${UNDO_VERIFICATION_URL}?checklistId=${checkListId}&creditorId=${creditorId}`, 'put');