import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { AuthService } from './authentication/authService';
import SideNavLayout from './components/layouts/SideNavLayout';
import TopBar from './components/layouts/TopBar';
import { DepositPage } from './components/pages/deposit/deposit';
import HomePage from './components/pages/home/HomePage';
import { IntentPage } from './components/pages/intent/IntentPage';
import NotFoundPage from './components/pages/NotFoundPage';
import UserSettingsPage from './components/pages/userSettings/UserSettingsPage';
import { UserAuthorizationService } from './components/UserAuthorizationService';
import {
    pagePath, topLevelPages
} from './pages';
import './styles/App.scss';
import './styles/Default.scss';
import { ScheduledPayments } from './components/pages/scheduledPayments/ScheduledPayments';
function App() {
    const open = localStorage.getItem('sideBarOpen') !== 'false' ? true : false;
    const [value, setvalue] = React.useState(open);
    const localStorageSetHandler = function(e: any) {
        if(open !== null){
            setvalue(!open );
        }
    };
    document.addEventListener("hidePanel", localStorageSetHandler, false);
    return (
        <div className="app">
            <div className="layout-root">
                <ToastProvider placement="bottom-right">
                    <AuthService>
                        {<UserAuthorizationService>
                            <BrowserRouter>
                                <SideNavLayout />
                                <TopBar />
                                <div className={`page-container ${value  ? 'open' : 'close'}`}>
                                        <Switch>
                                            <Route path={pagePath(topLevelPages.userSettings)}>
                                                <UserSettingsPage/>
                                            </Route>
                                            <Route path={pagePath(topLevelPages.intent)}>
                                                <IntentPage/>
                                            </Route>
                                            <Route path={pagePath(topLevelPages.deposit)}>
                                                <DepositPage/>
                                            </Route>                                            
                                            <Route path={pagePath(topLevelPages.home)} exact={true}>
                                                <HomePage />
                                            </Route>
                                            <Route path={pagePath(topLevelPages.scheduledPayments)} exact={true}>
                                                <ScheduledPayments />
                                            </Route>
                                            <Route path="/">
                                                <NotFoundPage />
                                            </Route>
                                        </Switch>
                                </div>
                            </BrowserRouter>
                        </UserAuthorizationService>}
                    </AuthService>
                </ToastProvider>
            </div>
        </div>
    );
}

export default App;
