import { PaymentType } from '../types/enums';

export function nameToFormattedString(
    firstName: string,
    middleInitial: string | null,
    lastName: string
) {
    let displayName = firstName;
    displayName += middleInitial ? ` ${middleInitial} ` : ' ';
    displayName += lastName;
    return displayName;
}

export function formatPhoneNumber(phoneNumber: string) {
    const phoneNumNoSpaces = phoneNumber.replace(/\s/g, '');

    switch (phoneNumNoSpaces.length) {
        case 7: // ex) 456-7890
            return `${phoneNumNoSpaces.substring(0, 3)}-${phoneNumNoSpaces.substring(3, 7)}`;
        case 10: // ex) 123-456-7890
            return `${phoneNumNoSpaces.substring(0, 3)}-${phoneNumNoSpaces.substring(3,6)}-${phoneNumNoSpaces.substring(6, 10)}`;
        case 11: // ex) 1-123-456-7890
            return `${phoneNumNoSpaces.substring(0,1)}-${phoneNumNoSpaces.substring(1,4)}-${phoneNumNoSpaces.substring(4, 7)}-${phoneNumNoSpaces.substring(7, 11)}`;
        default:
            return phoneNumber; // Unable to format
    }
}

export function formatAsCurrency(value: number) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(value);
}

export function removeSpaces(s: string) {
    return s.replace(/\s/g, '');
}

export function sanitizeFileName(name: string, replacementChar?: string) {
    if (!replacementChar) {
        replacementChar = '_';
    }
    var illegalRe = /[/?<>\\:*|"]/g;
    // eslint-disable-next-line no-control-regex
    var controlRe = /[\x00-\x1f\x80-\x9f]/g;
    var reservedRe = /^\.+$/;
    var windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
    var windowsTrailingRe = /[. ]+$/;
    var sanitized = name
        .replace(illegalRe, replacementChar)
        .replace(controlRe, replacementChar)
        .replace(reservedRe, replacementChar)
        .replace(windowsReservedRe, replacementChar)
        .replace(windowsTrailingRe, replacementChar);
    return sanitized.substring(0, 255);
}

export function paymentTypeToString(paymentType: PaymentType): string {    
    switch (paymentType) {
        case PaymentType.RamCheck:
            return 'RAM Check';
        case PaymentType.WireTransfer:
            return 'Wire Transfer';
        case PaymentType.Ach:
            return 'ACH';
        case PaymentType.AchDebit:
            return 'ACH';
        case PaymentType.PayByPhone:
            return 'Pay By Phone';
        default:
            return '';
    }
}

export function camelCaseToString(text: string) {
    const regex = /([a-z])([A-Z])/g;
    return text.replace(regex, (match, g1, g2) => {
        return g1 + ' ' + g2;
    });
}
