import { env } from 'process';
import { httpRequest, Urls } from './baseApi';

const BASE_URL = process.env.REACT_APP_CREDITOR_API_URL;

const DEPOSIT_URL = BASE_URL + '/Deposits/DepositRecords';
const  UPDATE_ACCOUNT_URL = BASE_URL + '/Deposits/updateAcctNumb';
const DEPOSIT_SUMMARY_URL = BASE_URL + '/Deposits/DepositSummary';

export const getDeposit = (id: any, begin: any, end : any) => httpRequest<any>(`${DEPOSIT_URL}?creditorId=${id}&beginDate=${begin}&endDate=${end}`, 'get');
export const getDepositSummary = (id: any, begin: any, end : any) => httpRequest<any>(`${DEPOSIT_SUMMARY_URL}?creditorId=${id}&beginDate=${begin}&endDate=${end}`, 'get');
export const updateAccountData = (data: any) => httpRequest<any>(`${UPDATE_ACCOUNT_URL}?settlementID=${data.settlementID}&newAcctNumb=${data.newAcctNumb}&paymentID=${data.paymentID}`,'post', data);





