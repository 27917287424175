import * as React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { InPlaceEditorComponent } from '@syncfusion/ej2-react-inplace-editor';
import '../../styles/common/ModalDialog.scss';
import { ModalProps } from '../../types/common';
import { useState } from 'react';

import { useEffect } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import notifyIcon from '../../assets/images/enableNotifications.png';
import disableNotifyIcon from '../../assets/images/disableNotification.png';

interface Props extends ModalProps {
    headerText?: string;
    message: any;
    type: string
    requireConfirmation: boolean;
    onConfirmClick?: (val?: any, data?: any) => void;
    onCancelClick?: () => void;
    popupType?: string;
    data?: any;
    updateAccount?:(data: any)=>void;
    updateAmount?:(data: any)=>void;
}

const MessagePopup = (props: Props) => {let ref = React.useRef(null);
    const confirmationButtons = [
        {
            
            buttonModel: {
                content: 'OK',
                isPrimary: true,          
                
            },
            click: () => {
                if(props.type=='userdata'){
                    props.onConfirmClick && props.onConfirmClick(dataRef?.current?.value,notifyUser);
                }
                else if(props?.type=== 'Edit'){
                  setshowInput(false);
                   if (props?.onConfirmClick) {
                    props?.onConfirmClick();
                }
                }
                else {
                    if(selectedValue === 'select'){
                        setReasonError(true);
                    }
                    else {
                        if(selectedValue === 'Other' && inputRef.current.value === ''){
                            setOtherError(true);
                        }
                        else {
                           
                            if (props?.onConfirmClick) {
                                props?.onConfirmClick(selectedValue === 'Other' ? `Other: ${inputRef.current.value}` : selectedValue);
                            }
                        }
                    }
                }
                
                
            }
        },
        {
            buttonModel: {
                content: 'Cancel'
            },
            click: () => {
                if (props?.onCancelClick) {
                    props?.onCancelClick();
                }
            }
        }
    ];
    const defaultButtons = [
        {
            buttonModel: {
                content: 'Close'
            },
            click: () => {
                props?.setShowDialog(false);
            }
        }
    ];
   
    const [selectedValue, setSelectedValue] = useState('select')
    const [account, setAccount] = useState(props?.data?.payeeAccountNumber)
    const [amount, setAmount] = useState(props?.data?.amount)

    const [reasonError, setReasonError] = useState(false);
    const [otherError, setOtherError] = useState(false);
    

    const [showInput, setshowInput] = useState(true);
    const [enableAmountEdit, setEnableAmountEdit] = useState(false); 
    const [enableAccountEdit, setEnableAccountEdit] = useState(false); 
    const [enableBankCodeEdit, setEnableBankCodeEdit] = useState(false); 
    const [isError, setIsError] = useState(false); 
    const [acntError, setAcntError] = useState(false); 
    

    useEffect(()=> {
         setAccount(props?.data?.payeeAccountNumber)
         setAmount(props?.data?.amount)
    }, [props?.data]);

    const [otherValue, setOtherValue]= useState('');
    function handleChange(e: any) {
        setOtherValue(e.target.value)
    }
    let inputRef: any = React.useRef<HTMLInputElement>(null);
    let dataRef: any = React.useRef<HTMLInputElement>(null);
    // let checkBoxRef: any = React.useRef<HTMLInputElement>(null);
    const [reason, setReason] = useState('');
    const rejectTemplate = ()=> {
        return <div className='reject-template'>
            <div className='reject-question'>{props?.message}</div>
            <select value={selectedValue} onChange={(e: any)=>{
                setSelectedValue(e.target.value)
            }} className={reasonError ? 'in-valid' : ''}>
                <option>Select</option>
                <option value='Closed'>Closed</option>
                <option value='Settled'>Settled</option>
                <option value='Not Found'>Not Found</option>
                <option value='Other'>Other</option>
            </select>
            {
                selectedValue === 'Other' && <div className='reject-reason'><label> Please specify a valid reason to reject this payment:
                    </label><input 
                    ref={inputRef}

                    className={otherError ? 'in-valid other-input' : 'other-input'}  type="text" >
                </input></div>
            }
        </div>
    }


    const BankTemplate = () => {
        return <>
            <div className='edit-form'> 
            <div>                        
                   <p>Account Name : {props?.data?.accountName}</p>
             </div>
             <div>                        
                <p>Account Number:  {props?.data?.accountNumber}</p>
             </div>
             <div>                        
                <p>Bank Name:  {props?.data?.bankName}</p>
             </div>
             <div>                        
                <p>Reliant Bank Code:  {props?.data?.reliantBankCode}</p>
             </div>
             <div>                        
                <p>Routing Number:  {props?.data?.routingNumber}</p>
             </div>
             </div>
        </>
    }

    const UserInfoTemplate = () => {
        return <>
            <div className='edit-form'> 
            <div>                        
                <p>Applicant Name: <b>{props?.data?.primaryContactName}</b></p>
             </div>
             <div>                        
                <p>Applicant SSN4: <b>{props?.data?.primaryContactSsn}</b></p>
             </div>
             <div>                        
                <p>Co-Applicant Name: <b>{props?.data?.secondaryContactName}</b></p>
             </div>
             <div>                        
                <p>Co-Applicant SSN4: <b>{props?.data?.secondaryContactSsn}</b></p>
             </div>
             <div>                        
                <p>Address: <b>{props?.data?.primaryContactAddress}</b></p>
             </div>
            </div>
        </>
    }

    const submitClick= ()=> {
       let amountEdit =  document.getElementById('amountEdit');
        let value = amountEdit && amountEdit.getElementsByClassName('e-editable-value')[0].innerHTML;
        props?.updateAmount && props?.updateAmount({
            id: props?.data?.transactionID,
            newAmount: value,
            oldAmount: props?.data?.amount
        })
    }
    const editTemplate = () => {
        return   <div className='edit-form'> 
            <div>                        
                   <p> Transaction ID : {props?.data?.transactionID}</p>
             </div>
             <div>                        
                <p> Client Name:  {props?.data?.clientName}</p>
             </div>
             <div>
                
        {
                    <> { !enableAccountEdit &&
                        <>Account Number : {account}
                        <span className='e-icons edit-icon-MessagePopup' 
                        onClick={() => {
                            setEnableAccountEdit(true)
                        }}></span></>
                    }
                    { enableAccountEdit && <>Account Number:
                     <input id="accountInput" type="text" ></input>
                    <ButtonComponent id='submit-btn' onClick={()=>{
                        let val = document.getElementById('accountInput') as any;
                        if(val && val.value){
                            setAcntError(false)
                            props?.updateAccount && props?.updateAccount({
                                id: props?.data?.transactionID,
                                account: val.value
                            });
                            setAccount(val.value)
                            setEnableAccountEdit(false)
                        }
                        else {
                            setAcntError(true);
                        }
                    }}>
                    <span className='e-icons e-check'></span>
                    </ButtonComponent>
                    <ButtonComponent id='close-btn' onClick={() => {
                        setEnableAccountEdit(false)
                    }}>
                    <span className='e-icons e-close'></span>
                    </ButtonComponent>
                    <div className={acntError ? ' error show' : ' error hide'}>
                    Please enter a valid Account Number
                </div>
                    </>}
                   </>

             }
            </div> 
            <div>
                {<> { !enableAmountEdit &&<p>Amount : {amount.includes('$') ? amount : '$'+amount} <span className='e-icons edit-icon-MessagePopup' 
                        onClick={() => {
                            setEnableAmountEdit(true)
                        }}></span></p>
                    }
                   { enableAmountEdit && <><p>Amount: 
                    <input id="amountInput" type="text" required={true}></input>
                   
                    <ButtonComponent type='submit' id='submit-btn' onClick={()=>{
                        let val = document.getElementById('amountInput') as any;
                        let oldVal = Number(props?.data?.amount.replace('$', '').replace(',',''));
                        if(val && val.value && Number(val.value) < oldVal ){
                            setIsError(false)
                            props?.updateAmount && props?.updateAmount(
                                {
                                    "currentAmount":  oldVal,
                                    "newAmount": Number(val.value),
                                    "settlementId": props?.data?.transactionID,
                                  }
                            )
                            setAmount(val.value)
                            setEnableAmountEdit(false)
                        }
                        else {                       
                            setIsError(true)
                        }
                    }}>
                    <span className='e-icons e-check'></span>
                    </ButtonComponent>
                    <ButtonComponent id='close-btn'  onClick={() => {
                            setEnableAmountEdit(false)
                        }}>
                    <span className='e-icons e-close' ></span>
                    </ButtonComponent>
                   </p>
                    <div className={isError ? ' error show' : ' error hide'}>
                    Please enter a valid amount and make sure that it is not greater than the existing payable amount.
                </div></>}
                   </>}
            </div> 

         </div>
    }
    const [notifyUser, setNotifyUser] = useState(false)

    const userDataTemplate = () => {
        return <div>
            <div className='modal-msg'>{props.message}</div>
            <div> <div className='modal-notes'>Please enter any notes for todays payments: </div>
                <input 
                    ref={dataRef} type="text" autoFocus={false}>
                </input>
                <img className='modal-img' title="Notify Creditor" src={notifyUser ? notifyIcon : disableNotifyIcon} onClick={()=>setNotifyUser(!notifyUser)}></img>
               
                </div>
        </div>
    }

    return (<>
       { <DialogComponent
            header={props?.headerText}
            isModal={true}
            buttons={ props?.requireConfirmation ? confirmationButtons : defaultButtons}
            target={props?.targetId ? '#' + props?.targetId : undefined}
            visible={props?.showDialog}
            showCloseIcon={true}
            close={() => props?.setShowDialog(false)}
            width="500px"
            height="auto"
            content = {props.type === 'userdata' ? userDataTemplate :
                props?.type === 'Edit' ? editTemplate : (props?.type === 'Reject' ? rejectTemplate : (props?.type === 'UserInfo' ? UserInfoTemplate : BankTemplate))}
        >
        </DialogComponent>}</>
    );
};

export default MessagePopup;
