import * as React from 'react';
import { useContext, useState } from 'react';
import logo from '../../assets/images/logo.png';
import menuclosed from '../../assets/images/menuclosed.png';
import menuopened from '../../assets/images/menuopened.png';
import { AuthContext } from '../../authentication/authContext';
import '../../styles/components/TopBar.scss';
import MenuSelect from '../common/MenuSelect';

const TopBar = () => {
    const {  logout, username} = useContext(AuthContext);
    const open = localStorage.getItem('sideBarOpen') !== 'false' ? true : false;
    const [sideBarOpen, setSideBarOpen] = useState(open);
    return (
        <div className= "top-bar-container">
            <div className="top-bar-layout">
            <div className="app-logo">
            <img src={sideBarOpen ? menuopened : menuclosed } 
                onClick={
                    () => {
                        setSideBarOpen(!sideBarOpen);
                        const originalSetItem = localStorage.setItem;

                        localStorage.setItem = function(...args) {
                        const event = new Event('hidePanel');
                        document.dispatchEvent(event);

                        originalSetItem.apply(this, args);
                        };                        
                        localStorage.setItem('sideBarOpen', String(!sideBarOpen))
                    }
                } alt="Logo" />
                <img src={logo} alt="Logo" />
            </div>
                <MenuSelect menuName={username ?? 'User'}>
                   <div onClick={logout}>Logout</div>
                </MenuSelect>
            </div>
            <hr />
        </div>
    );
};

export default TopBar;
