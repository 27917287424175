import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { AuthContext } from './authContext';
import { useToasts } from 'react-toast-notifications';
import { resetUserPassword } from '../api/userApi';
import {
    getToken,
    isTokenRefreshRequired,
    directToLogin,
    stripTokenFromUrl
} from './authFunctions';
import { GridViewContext, GridViewService } from '../components/GridViewService';
import { LoginPage } from '../components/pages/login/LoginPage';
import { ResetPassword } from '../components/pages/resetPasswordPage/ResetPassword';

export interface IdentityUser {
    username: string | null;
    userId: string | null;
    companyId: string | null;
    name: string | null;
    roles: string[];
    expiration: number | null;
    email: string | null;
    clientId: string | null; // API username
    vendorId: string | null;
    creditorId: string | null;
    role: string | null;
}

const useAuthSetup = (jwt?: any, passwordExpired?: boolean) => {
    const [loginError, setLoginError] = useState(false);
    const { addToast } = useToasts();
    // Pull jwt from query parameter if it is there
    if (jwt && !passwordExpired) {
        var { expiration, vendorId, creditorId, role } = getUserDataFromToken(jwt);
    expiration && localStorage.setItem('tokenExpDate', expiration.toString());
        if(vendorId && !loginError){
            setLoginError(true);
        }
        else if(!vendorId){
            localStorage.setItem('token', jwt.replaceAll('"', ''))
            if(window.location.href.includes('returnUrl')){
                window.location.href = decodeURI(window.location.href.split('returnUrl=/')[1]) ?? 'home'
            }
        }
        
    creditorId && role!== null && role.includes('Superuser') && localStorage.setItem('id', creditorId )   
     }
     if(passwordExpired){
        window.location.href = '/resetPassword';
    }

    // Get token from storage
    const token = getToken();
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpDate');
        localStorage.removeItem('id');
        localStorage.removeItem('sideBarOpen');
        directToLogin();
    };

    if(token && !isTokenRefreshRequired() && window.location.pathname === '/'){
        window.location.href = '/home';
    }

    // Direct user to login if refresh is required
    if (((token === null || isTokenRefreshRequired()) && window.location.pathname !== '/login') && window.location.pathname !== '/resetPassword')  {
        logout();
        return;
    }

    // Get user info from token
    const loggedInUser = token && getUserDataFromToken(token as string);


    const changePassword = async () => {
        if (loggedInUser && !loggedInUser.userId) {
            addToast('Unknown user', { appearance: 'error', autoDismiss: true });
            return;
        }
        const userId = loggedInUser && loggedInUser.userId;
        const result = userId && await resetUserPassword(userId);
        if (result && result.error && result.error) {
            addToast(result.error, { appearance: 'error', autoDismiss: true });
            return;
        }
        addToast('You will be contacted to reset your password', {
            appearance: 'success',
            autoDismiss: true
        });
    };
    const isUnlimitedUser = () => {
        return loggedInUser && loggedInUser.roles && loggedInUser.roles.includes('Unlimited');
    };
   

    return {value:{
        ...loggedInUser,
        logout,
        changePassword,
        isUnlimitedUser,
        loggedInUser
    }, error: loginError};
};

export function getUserDataFromToken(jwt: string): IdentityUser {
    const decoded: any = jwt_decode(jwt);
    const usernameIdKey = Object.keys(decoded).find(k => k === 'preferred_username');
    const userIdKey = Object.keys(decoded).find(k => k === 'sub');
    const companyIdKey = Object.keys(decoded).find(k => k === 'vendorID');
    const nameKey = Object.keys(decoded).find(k => k === 'name');
    const roleKey = Object.keys(decoded).find(k => k === 'role');
    const expirationKey = Object.keys(decoded).find(k => k === 'exp');
    const emailKey = Object.keys(decoded).find(k => k === 'email');
    const clientKey = Object.keys(decoded).find(k => k === 'client_id');
    const vendorId = Object.keys(decoded).find(k => k === 'vendorId');
    const creditorId = Object.keys(decoded).find(k => k === 'creditorid');
    const role = Object.keys(decoded).find(k => k === 'role');
    return {
        username: decoded[usernameIdKey as string] as string,
        userId: decoded[userIdKey as string] as string,
        companyId: decoded[companyIdKey as string] as string,
        name: decoded[nameKey as string] as string,
        roles: decoded[roleKey as string] as string[],
        expiration: decoded[expirationKey as string] as number,
        email: decoded[emailKey as string] as string,
        clientId: decoded[clientKey as string] as string,
        vendorId: decoded[vendorId as string] as string,
        creditorId: decoded[creditorId as string] as string,
        role: decoded[role as string] as string,
    };
}

interface QueryStringParams {
    jwt?: string;
}

export const AuthService = ({ children }: { children?: any }) => {
    const [token, setToken] = useState<any>(null);
    const [passwordExpired, setPasswordExpired] = useState<any>(false);
    const data =  useAuthSetup(token, passwordExpired);
    const val = data?.value;
    const [permissions, setpermissions] = React.useState(undefined);
    const creditorId = val && val.loggedInUser &&   val?.loggedInUser?.creditorId;
    const [id, setId] =  React.useState(creditorId);
    const savePermissions = (permissions: any) => {
        setpermissions(permissions)
    }
    const changeUser = (val: any) => {
        setId(val)
    }
    return window.location.pathname==='/resetPassword' ? <ResetPassword></ResetPassword> : (val && id ? (
    // return val && id ? (
        <AuthContext.Provider value={{...val, id, changeUser, savePermissions, permissions}}>
                <GridViewContext.Provider value={GridViewService(permissions) }>
                {children}
                </GridViewContext.Provider>
        </AuthContext.Provider>    ) : (
       <>
         <LoginPage loginError={data?.error} setAuthToken={(token: string)=>{setToken(token)}} 
        setPasswordExpired={((val : boolean)=>{
            setPasswordExpired(val)})}/></>
    ));
};
