import { useHistory } from 'react-router-dom';
import { Page, pagePath } from '../pages';

export const useNavigation = () => {
    const history = useHistory();

    const navigateToPage = (page: Page) => {
        const path = pagePath(page);
        history.push(path);
    };

    return navigateToPage;
};
