export interface Page {
    name: string;
    extension: string;
}

export const topLevelPages: {
    home: Page;
    userSettings: Page;
    intent: Page;
    deposit:Page;
    resetPassword: Page;
    scheduledPayments: Page;
} = {
    home: {
        name: 'Home',
        extension: 'home'
    },
    userSettings: {
        name:'userSettings',
        extension: 'userSettings'
    },
    intent: {
        name:'intent',
        extension: 'intent'
    }
    , deposit: {
        name:'deposit',
        extension: 'deposit'
    }
    , resetPassword: {
        name:'resetPassword',
        extension: 'resetPassword'
    }
    , scheduledPayments: {
        name:'scheduledPayments',
        extension: 'scheduledPayments'
    }

};
export interface Section {
    name: string;
    extension: string;
    navigationPages?: Page[];
}
export const getSectionExtension = (page: Page) => {
    if (Object.values(topLevelPages).includes(page)) {
        return undefined;
    }

    return undefined;
};
export const renderUrl = (sectionExtension: string) => (pageExtension: string) =>
    `/${sectionExtension}/${pageExtension}`;

export const pagePath = (page: Page) => {
    const sectionExtension = getSectionExtension(page);
    return sectionExtension ? renderUrl(sectionExtension)(page.extension) : `/${page.extension}`;
};
export const contactUsPageLink = 'https://reliantpayment.com/contact-us/';
