import * as React from 'react';

interface Props {
    label: string;
    icon: string;
    onClick?: () => void;
}

const SideNavHeaderLayout = (props: Props) => (
    <h3 onClick={props.onClick}>
        <img src={props.icon} alt={props.label} className="header-icon" />
        {props.label}
    </h3>
);

export default SideNavHeaderLayout;
