import { selectionComplete } from '@syncfusion/ej2-react-charts';
import {
    Aggregate, ColumnDirective, ColumnsDirective, CommandColumn, dataBound, Edit, ExcelExport,
    Filter, FilterSettingsModel, GridComponent, Inject, PdfExport,
    Resize, Sort, Toolbar
} from '@syncfusion/ej2-react-grids';
import * as React from 'react';
import { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { loadFromApi } from '../../../api/baseApi';
import { getChecklist, sendReminder, undoVerification, updateChecklist } from '../../../api/homeApi';
import { AuthContext } from '../../../authentication/authContext';
import { useNavigation } from '../../../hooks/useNavigation';
import { topLevelPages } from '../../../pages';
import '../../../styles/views/Home.scss';
import { useCommonGridOptions } from '../../../utils/gridUtils';
import { camelCaseToString } from '../../../utils/stringUtils';
import MessagePopup from '../../common/MessagePopup';
import { GridViewContext } from "../../GridViewService";
import bell from '../../../assets/images/enableNotifications.png';
import bellDisabled from '../../../assets/images/disableNotification.png';

function HomePage() {
    const goToPage = useNavigation();
    const {  loggedInUser} = React.useContext(AuthContext);

    useEffect(()=> {
        if(loggedInUser && !loggedInUser?.role?.includes('Superuser')){
            goToPage(topLevelPages.intent);
        }
    }, [])
    const { addToast } = useToasts();
    const { currentView } = React.useContext(GridViewContext);
    const setApiError = useCallback(
        (error?: string) => error || error=='' && addToast(error != '' ? error : 'An error occured. Please contact help desk if it presists.'
            , { appearance: 'error', autoDismiss: true }),
        [addToast]
    );
    const [userData, setUserData] = useState([]);
    const [notes, setNotes] = useState('');
    const [notifyUser, setNotifyUser] = useState(false)
    const [showDialog, setShowDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>({});
    const [complete, setComplete] = useState(false);
    const [columns, setColumns] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [completeResult, setCompleteResult] = useState<any>({});

    let dataBound = () =>{
        if(gridOptions.ref.current && currentView === 2)
        {
                gridOptions.ref.current.autoFitColumns([])
        }
    }
    useEffect(() => {
        if(complete){
            const HomePageApiWrapper = loadFromApi(setApiError);
            setLoading(true)
            const saveUserData = async () => { 
                await HomePageApiWrapper(()=> updateChecklist({
                    completedBy: selectedRowData.completedBy,
                    completedDate: selectedRowData.completedDate,
                    creditorID: selectedRowData.creditorID,
                    creditorName: selectedRowData.creditorName,
                    id:selectedRowData.id,
                    notes: notes,
                    paymentDate: selectedRowData.paymentDate,
                    paymentsVolume: selectedRowData.paymentsVolume,
                    verificationComplete: true,
                    notifyCreditor: notifyUser
                }), setCompleteResult);
                getUserData();  

            };               
            saveUserData();  
            const getUserData = async () => { 
                await HomePageApiWrapper(()=> getChecklist(), setUserData);
                setLoading(false)
                setComplete(false)
            };
        }
        
    }, [complete])

    useEffect(() =>{
        if(completeResult?.isSuccessful === false){
            addToast(`Unable to complete verification for ${selectedRowData.creditorName}`,{ appearance: 'error', autoDismiss: true })
            setCompleteResult({});
        }
        if(completeResult?.isSuccessful === true){
            addToast(`Succesfully verified ${selectedRowData.creditorName}`,{ appearance: 'success', autoDismiss: true })
            setCompleteResult({});
        }
    }, [completeResult])
    useEffect(() => {
        const HomePageApiWrapper = loadFromApi(setApiError);
        setLoading(true)
        const getUserData = async () => { 
                       
            await HomePageApiWrapper(()=> getChecklist(), setUserData);
            setLoading(false)
        };               
        getUserData();  
                      
    }, [setApiError]);

    const [reminderData, setReminderData] = useState<any>({});

    useEffect(()=>{
        if(reminderData?.isSuccessful === true){
            addToast(`Reminder Sent`,{ appearance: 'success', autoDismiss: true })
        }
        else if(reminderData?.isSuccessful === false) {
            addToast(`Error sending reminder`,{ appearance: 'error', autoDismiss: true })
        }
    }, [reminderData?.isSuccessful])

    const undoVerificationClick = async(checklistId: any, creditorId: any) => {
        const HomePageApiWrapper = loadFromApi(setApiError);
        setLoading(true)
        await HomePageApiWrapper(()=> undoVerification(checklistId, creditorId), setReminderData);
        await HomePageApiWrapper(()=> getChecklist(), setUserData);
        setLoading(false) 
    }

    const sendReminderRequest = async(id: any) => {
        setReminderData(undefined);
        const HomePageApiWrapper = loadFromApi(setApiError);
        setLoading(true)
        await HomePageApiWrapper(()=> sendReminder(id), setReminderData);
        await HomePageApiWrapper(()=> getChecklist(), setUserData);
        setLoading(false) 
    }

    const filterOptions: FilterSettingsModel = {
        type: 'Excel'
    };
    useEffect(()=> {
        setColumns(['paymentDate','creditorName', "paymentsVolume", "verificationComplete", "notes", "undoVerification"])
    }, [userData])
    const gridOptions = useCommonGridOptions('');

    return (
        <div className="page-home">
            <div className='home-heading'>ACH Creditor Checklist</div>
            <GridComponent
        ref={gridOptions.ref}
        allowSorting={true}
        allowFiltering={true}
        filterSettings={filterOptions}
        rowHeight={currentView === 3 ? 20 : 40}
        dataBound={dataBound}
        dataSource={userData}
        clipMode='EllipsisWithTooltip'
    >
        {   
            userData.length > 0 && <ColumnsDirective>             
                {
                    columns.map((key: string, index: number)=>{
                    const header = camelCaseToString(key);
                        return <ColumnDirective
                            key={index}
                            field={key}
                            allowFiltering={true}
                            headerText={key === "undoVerification" ? "" : header.substring(0,1).toUpperCase()+header.substring(1)}
                            width="100px"
                            filterItemTemplate=
                            {
                                (key === 'paymentDate' ? (props: any) =>
                                    {
                                        return <span>
                                                {props['paymentDate'].split('-')[1]}/{props['paymentDate'].split('-')[2].split('T')[0]}/{props['paymentDate'].split('-')[0]}
                                            </span>
                                    } : undefined
                                )
                            }
                            template=
                            {
                                (key === 'verificationComplete' ? (props: any) => 
                                    {
                                        return <>{!props.verificationComplete && <button className={props['complete'] ?  'complete-icon':'not-complete-icon'}
                                        onClick={()=>{
                                            setShowDialog(true)
                                            setSelectedRowData(props)
                                        }} 
                                        >Complete
                                        </button>}
                                        {
                                            props.verificationComplete ? props.completedBy : ''
                                        }
                                        </>
                                    } : (key==='creditorName' ? (props: any) => <a
                                onClick={()=> {
                                localStorage.setItem('id', props['creditorID'])
                            }}
                            href='/intent'
                            >
                                {props[key]}
                            </a> : ((key==='paymentDate' ? (props: any) => <span>{props['paymentDate'].split('-')[1]}/{props['paymentDate'].split('-')[2].split('T')[0]}/{props['paymentDate'].split('-')[0]}</span>
                             : (key === 'undoVerification' ? (props: any) => 
                                {
                                    return <>{props.undoVerification && <button className={'undo-verification-icon'}
                                    onClick={()=>undoVerificationClick(props.id, props.creditorID)} 
                                    >Undo Verification
                                    </button>}
                                    </>
                                } : undefined))))) }  
                        >
                        </ColumnDirective>
                    })
                }   
                <ColumnDirective
                    width="5%" 
                    field=""
                    template={(props:any)=> {
                        return <button className='reminder-button' disabled={props.reminderSent} onClick={()=> {sendReminderRequest(props.creditorID)}}><img src={props.reminderSent ? bellDisabled : bell}></img></button>
                    }} 
                >
                </ColumnDirective>
            </ColumnsDirective>
        }
        <Inject services={[Edit, CommandColumn, PdfExport, ExcelExport, Toolbar, Filter,Aggregate,Resize, Sort]} />
    </GridComponent>  
    {showDialog && <MessagePopup
        requireConfirmation={true}
        message={ "Are you sure you want to complete verification?" }
        showDialog= {showDialog}
        type={"userdata"}
        setShowDialog={(val)=>{
            setShowDialog(val)
        }}
        onConfirmClick={(data: any, value: any) => {
            setShowDialog(false)
            setNotes(data);
            setNotifyUser(value);
            setComplete(true);
         }}
        onCancelClick={() => {
            setComplete(false);
            setShowDialog(false)
        }}
    ></MessagePopup>}            
        </div>
    );
}

export default HomePage;
