import * as React from 'react';
import '../../styles/components/LoadingDisplay.scss';

interface Props {}

const LoadingDisplay = (props: Props) => (
    <div className="spinner-container">
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default LoadingDisplay;
