import * as React from 'react';
import '../../styles/components/MenuSelect.scss';
import dropdownCarotIcon from '../../assets/images/dropdown-carot.png';
import { ReactNode, useState } from 'react';
import { useEffect } from 'react';

interface Props {
    menuName: string;
    children?: ReactNode;
    caretOnRight?: boolean;
    onClose?: () => void;
}

const MenuSelect = (props: Props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const closeMenu = () => {
            setIsMenuOpen(false);
            props.onClose && props.onClose();
        };

        if (isMenuOpen) {
            window.addEventListener('click', closeMenu);
        } else {
            window.removeEventListener('click', closeMenu);
        }
        return () => {
            window.removeEventListener('click', closeMenu);
        };
    }, [isMenuOpen, props]);

    const onMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setIsMenuOpen(!isMenuOpen);
    };

    const optionClicked = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    };

    return (
        <div className="dropdown">
            <div
                className={`dropdown-menu-button ${props.caretOnRight ? 'reversed' : ''}`}
                onClick={onMenuClick}
            >
                <img src={dropdownCarotIcon} alt="menu" />
                {props.menuName}
            </div>
            {isMenuOpen && (
                <div className="dropdown-menu" onClick={optionClicked}>
                    {props.children}
                </div>
            )}
        </div>
    );
};

export default MenuSelect;
